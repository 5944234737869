import React from 'react';
import PropTypes from 'prop-types';
import { getOr, map, get, size, find, isEmpty } from 'lodash/fp';
import { String } from '../String/String';
import { Tooltip } from '../Tooltip/Tooltip';
import { ProductPrice } from '../ProductPrice/ProductPrice';
import { RadioButton } from '../RadioButton/RadioButton';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { Conditional } from '../Conditional/Conditional';
import { connect } from '../../store/store';
import {
  ProductCardRoot,
  ProductCardImageContainer,
  ProductCardImage,
  ProductCardImageHideOnHover,
  ProductCardImageHover,
  ProductCardTitle,
  ProductCardDescription,
  ProductCardLabelContainer,
  ProductCardLabel,
  SustainabilityIconsContainer,
  SustainabilityIcon,
  RadioButtonContainer,
  RadioButtons,
  SizeAndStockContainer,
  SizeAndStock,
  SizeColor,
} from './styles';
import { ISOLATED_IMAGE, MODEL_IMAGE } from '../../constants/mediaType';
import { REUSE_PRODUCT } from '../../constants/cmsTypes';
import { renderSustainabilitySvg } from '../../helpers/renderSustainabilitySvg';
import { sizesOrder } from './sizesOrder';

class ProductCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeChoiceMade: !props.coverImage,
      selectedVariantId: getOr(
        {},
        [
          'product',
          'variants',
          this.props.product.selectedVariantIndex || 0,
          'id',
        ],
        props
      ),
      numberOfVariants: getOr([], ['product', 'variants'], props),
      hoverImage: '',
    };

    this.renderLabels = this.renderLabels.bind(this);
    this.renderSizes = this.renderSizes.bind(this);
    this.renderRadioButtons = this.renderRadioButtons.bind(this);
    this.showFeature = this.showFeature.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    // THIS... "if number if variants on product has changed, reselect selectedVariantId"
    // ...was removed since Houdini wants to select the first variant when you change category.
    // Number of variants change for example when you go from Deals to Women / Pants.
    // See https://jira.cloudnine.se/browse/HOUD-719 for more info.

    const previousVariants = state.numberOfVariants;
    const nextVariants = getOr([], ['product', 'variants'], props);
    const numberOfVariantsHasChanged =
      size(previousVariants) !== size(nextVariants);

    // ...instead select the first variant.
    if (numberOfVariantsHasChanged) {
      const firstVariantId = getOr(
        {},
        ['product', 'variants', '0', 'id'],
        props
      );
      return {
        selectedVariantId: firstVariantId,
        numberOfVariants: nextVariants,
      };
    }

    return null;
  }

  showFeature(feature) {
    // Always return true if there are no declared disabled features
    if (this.props.disabledFeatures.length === 0) {
      return true;
    }

    // Always return false if 'disableAll' is selected
    if (this.props.disabledFeatures.includes('disableAll')) {
      return false;
    }

    return !this.props.disabledFeatures.includes(feature);
  }

  selectVariantHandler(id) {
    this.setState({
      activeChoiceMade: true,
      selectedVariantId: id,
    });
  }

  renderLabels(labels = []) {
    return labels.map((label) => (
      <ProductCardLabel key={get('id', label)} color={get('color', label)}>
        {get('title', label)}
      </ProductCardLabel>
    ));
  }

  renderSizes(sizes = []) {
    const sorted = sizes.sort(
      (a, b) =>
        sizesOrder.indexOf(a.shortTitle) - sizesOrder.indexOf(b.shortTitle)
    );

    return sorted.map((sizeItem) => (
      <SizeAndStock
        key={sizeItem.title + sizeItem.stockBalance}
        stock={sizeItem.stockBalance}
      >
        {get('shortTitle', sizeItem)}
      </SizeAndStock>
    ));
  }

  renderRadioButtons(variants) {
    return variants.map((variant) => {
      const id = `variant-${variant.id}-${this.props.product.variant_index}`;
      const name = `variant-color-${this.props.product.title}`;

      return (
        <Tooltip key={`${variant.id}`} content={get('color.title', variant)}>
          <RadioButton
            id={id}
            name={name}
            disabled={getOr([], 'survivedFilter', this.props.product).includes(
              variant.id
            )}
            color={get('color.id', variant)?.toUpperCase()}
            size={16}
            spacing={8}
            checked={
              variant.id === this.state.selectedVariantId &&
              this.state.activeChoiceMade
            }
            onChange={() => this.selectVariantHandler(variant.id)}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={id} />
        </Tooltip>
      );
    });
  }

  render() {
    const { product, coverImage, storeEnabled, onGoToProduct, isMobile } =
      this.props;

    if (!product) {
      return false;
    }

    const {
      id,
      title,
      shortDescription,
      variants,
      sustainabilityOptions,
      labels,
      reuseCondition,
    } = product;

    if (!id || !title || !variants) {
      return false;
    }

    const hasDirectURL = Boolean(this.props.product?.page_url);

    let url, splitURL, directURL;
    if (hasDirectURL) {
      url = new URL(this.props.product?.page_url);
      splitURL = url.pathname.split('/');
      directURL = splitURL.slice(2, splitURL.length).join('/') + url.search;
    }

    const selectedVariant = variants
      ? variants.find((x) => x.id === this.state.selectedVariantId)
      : null;

    const primaryModelImage = find(
      (image) => image.mediaType === MODEL_IMAGE,
      get('images', selectedVariant)
    );
    const modelImage = isEmpty(primaryModelImage)
      ? get(['images', 0], selectedVariant)
      : primaryModelImage;

    const primaryIsolatedImage = find(
      (image) => image.mediaType === ISOLATED_IMAGE,
      get('images', selectedVariant)
    );

    if (!selectedVariant) {
      // Error: No variant selected for ProductCard
      return false;
    }

    const firstVariantSizeWithLabels = find((variantSize) => {
      return !isEmpty(get('labels', variantSize));
    }, get('sizes', selectedVariant));

    const variantLabels =
      get('labels', selectedVariant) ||
      get('labels', firstVariantSizeWithLabels);
    const labelsToRender = !isEmpty(variantLabels)
      ? [
          ...new Map(
            [...labels, ...variantLabels].map((i) => [i.id, i])
          ).values(),
        ]
      : labels;

    const renderLabels = this.renderLabels(labelsToRender);
    const renderSizes = this.renderSizes(selectedVariant.sizes);

    const depictEnabled = !isEmpty(this.props.product.depictConfig);
    const depictConfig = this.props.product.depictConfig;

    const cardAttributes = {
      ...(depictEnabled && {
        ...(depictConfig.recommendationId && {
          'data-recommendation-id': depictConfig.recommendationId,
        }),
        ...(depictConfig.listResultId && {
          'data-product-listing-result-id': depictConfig.listResultId,
        }),
        ...(depictConfig.searchResultId && {
          'data-search-result-id': depictConfig.searchResultId,
        }),
      }),
    };

    const loadHoverImage = () => {
      if (!this.state.hoverImage) {
        const hoverImage = get('urlPath', primaryIsolatedImage);
        this.setState({ hoverImage });
      }
    };

    return (
      <ProductCardRoot {...cardAttributes}>
        <Conditional show={this.state.activeChoiceMade}>
          <ProductCardLabelContainer>{renderLabels}</ProductCardLabelContainer>
        </Conditional>

        <LocalizedLink
          as={directURL || `${id}?color=${this.state.selectedVariantId}`}
          page={get('type', product)}
          data={{ id, color: this.state.selectedVariantId }}
        >
          <ProductCardImageContainer onClick={onGoToProduct}>
            <Conditional show={!this.state.activeChoiceMade}>
              <ProductCardImage
                src={get('urlPath', coverImage)}
                heightRatio={1.5}
                sizes="(max-width: 830px) 50vw, (max-width: 1024px) 33vw, 25vw"
                alt={get('alt', coverImage)}
                layout={'fill'}
                loadInstantly
              />
            </Conditional>

            <Conditional
              show={!primaryIsolatedImage && this.state.activeChoiceMade}
            >
              <ProductCardImage
                src={get('urlPath', modelImage)}
                heightRatio={1.5}
                sizes="(max-width: 830px) 50vw, (max-width: 1024px) 33vw, 25vw"
                alt={get('alt', modelImage)}
                layout={'fill'}
                loadInstantly
              />
            </Conditional>

            <Conditional
              show={!!primaryIsolatedImage && this.state.activeChoiceMade}
            >
              <ProductCardImageHideOnHover
                src={get('urlPath', modelImage)}
                heightRatio={1.5}
                sizes="(max-width: 830px) 50vw, (max-width: 1024px) 33vw, 25vw"
                alt={get('alt', modelImage)}
                layout={'fill'}
                loadInstantly
              />

              <ProductCardImageHover
                // Commented out hoverImage since it's not used for now
                // src={this.state.hoverImage}
                src={get('urlPath', primaryIsolatedImage)}
                heightRatio={1.5}
                sizes="(max-width: 830px) 50vw, (max-width: 1024px) 33vw, 25vw"
                alt={get('alt', primaryIsolatedImage)}
                layout={'fill'}
                isProductCard
                // onMouseEnter={loadHoverImage}
              />
            </Conditional>

            <Conditional show={this.state.activeChoiceMade}>
              <SustainabilityIconsContainer>
                <Conditional show={get('type', product) === REUSE_PRODUCT}>
                  <Tooltip content="Reuse">
                    <Conditional show={isMobile}>
                      <SustainabilityIcon>
                        {renderSustainabilitySvg('Reuse', '11px', '11px')}
                      </SustainabilityIcon>
                    </Conditional>
                    <Conditional show={!isMobile}>
                      <SustainabilityIcon>
                        {renderSustainabilitySvg('Reuse', '20px', '20px')}
                      </SustainabilityIcon>
                    </Conditional>
                  </Tooltip>
                  {map(
                    (icon) => (
                      <Tooltip key={icon.id} content={icon.title}>
                        <Conditional show={isMobile}>
                          <SustainabilityIcon>
                            {renderSustainabilitySvg(icon.id, '11px', '11px')}
                          </SustainabilityIcon>
                        </Conditional>
                        <Conditional show={!isMobile}>
                          <SustainabilityIcon>
                            {renderSustainabilitySvg(icon.id, '20px', '20px')}
                          </SustainabilityIcon>
                        </Conditional>
                      </Tooltip>
                    ),
                    reuseCondition
                  )}
                </Conditional>

                <Conditional show={get('type', product) !== REUSE_PRODUCT}>
                  {map(
                    (icon) => (
                      <Tooltip key={icon.id} content={icon.title}>
                        <Conditional show={isMobile}>
                          <SustainabilityIcon>
                            {renderSustainabilitySvg(icon.id, '11px', '11px')}
                          </SustainabilityIcon>
                        </Conditional>
                        <Conditional show={!isMobile}>
                          <SustainabilityIcon>
                            {renderSustainabilitySvg(icon.id, '20px', '20px')}
                          </SustainabilityIcon>
                        </Conditional>
                      </Tooltip>
                    ),
                    sustainabilityOptions
                  )}
                </Conditional>
              </SustainabilityIconsContainer>
            </Conditional>

            <Conditional show={storeEnabled && this.state.activeChoiceMade}>
              <SizeAndStockContainer
                showSize={getOr(
                  false,
                  'displaySizesInQuickView',
                  selectedVariant
                )}
              >
                <p>
                  <String id="productCardAvailableVariantSizesLabel" />
                  <SizeColor>
                    {get(['color', 'title'], selectedVariant)}
                  </SizeColor>
                  :
                </p>
                <div>{renderSizes}</div>
              </SizeAndStockContainer>
            </Conditional>
          </ProductCardImageContainer>
        </LocalizedLink>

        <Conditional show={this.showFeature('disableTitle')}>
          <LocalizedLink
            as={`${id}?color=${this.state.selectedVariantId}`}
            page={get('type', product)}
            data={{ id, color: this.state.selectedVariantId }}
          >
            <ProductCardTitle onClick={onGoToProduct}>{title}</ProductCardTitle>
          </LocalizedLink>
        </Conditional>

        <Conditional show={this.showFeature('disableShortDescription')}>
          <ProductCardDescription>{shortDescription}</ProductCardDescription>
        </Conditional>

        <Conditional show={storeEnabled && this.showFeature('disablePrice')}>
          <ProductPrice currentVariant={selectedVariant} />
        </Conditional>

        <Conditional show={this.showFeature('disableVariants')}>
          {variants?.length > 0 && (
            <RadioButtons>
              <RadioButtonContainer>
                {this.renderRadioButtons(variants)}
              </RadioButtonContainer>
            </RadioButtons>
          )}
        </Conditional>
      </ProductCardRoot>
    );
  }
}

ProductCardComponent.defaultProps = {
  coverImage: null,
  storeEnabled: true,
  onGoToProduct: () => {},
  listIndex: null,
  isMobile: false,
  disabledFeatures: [],
};

ProductCardComponent.propTypes = {
  product: PropTypes.object.isRequired,
  coverImage: PropTypes.object,
  storeEnabled: PropTypes.bool,
  onGoToProduct: PropTypes.func,
  listIndex: PropTypes.number,
  isMobile: PropTypes.bool,
  disabledFeatures: PropTypes.array,
};

export const ProductCard = connect(ProductCardComponent, {}, (store) => ({
  storeEnabled: get(['bootstrap', 'data', 'storeEnabled'], store),
  isMobile: get('viewState.isMobile', store),
}));
